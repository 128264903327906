body {
    background-color: #f5f5f5;
  }
  
  .section {
    position: relative;
    height: 100vh;
  }
  
  /* CSS For About Page */
  .about-container {
    padding-bottom: 1rem;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
  }
  .img_profile {
    width: 20rem;
    margin: 0.5rem;
    border-radius: 50%;
    border: 3px solid #20247b;
    transition: transform 0.2s ease-in;
    cursor: pointer;
  }
  .img_profile:hover {
    transform: scale(1.01);
    transition: transform 0.2s ease-in;
  }

  .customBtn{
    background-color: #20247b;
    color: white;
    border: none;
  }
  .customBtn:hover{
    background-color: #2a36b6;
    color: white;
  }
  /* About Me 
  ---------------------*/
  .about-details h3 {
    font-size: 3rem;
    font-weight: 700;
    margin: 0 0 0.1rem;
  }
  .about-details h6 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .about-details p {
    font-size: 1.2rem;
    max-width: 28rem;
  }
  .about-details p mark {
    font-weight: 600;
    color: #20247b;
  }
  
  .about-section .counter {
    padding: 1.3rem 1.25rem;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  }
  
  mark {
    background: linear-gradient(
        to left,
        rgba(255, 0, 0, 1),
        rgba(255, 0, 180, 1),
        rgba(0, 100, 200, 1)
    );
    background-size: 100% 3px, 0 3px;
    background-position: 0 bottom;
    background-repeat: no-repeat;
    transition: background-size 400ms;
    padding: 0.2rem;
  }
  mark:hover {
    background-size: 0 3px, 100% 3px;
  }
  .theme-color {
    color: #fc5356;
  }
  .dark-color {
    color: #20247b;
  }
  
  .home_social-icon {
    text-decoration: none;
    width: max-content;
    font-size: 1.7rem;
    color: #20247b;
    margin-right: 1.7rem;
    transition: all 0.2s ease-in-out;
  }
  .home_social-icon:hover {
    color: #2a36b6;
    transform: scale(1.4);
  }
  
  /* CSS For Projects Page */
  .pb-6,
  .py-6 {
    padding-bottom: 3.75rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 3rem !important;
  }
  .hover-scale,
  .hover-scale:hover {
    transition: transform 0.2s ease-in;
  }
  .card {
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5%;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    height: 22rem;
    width: 21rem;
    margin: 1rem;
  }
  .card-body {
    min-height: 1px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .project-title {
    margin: 1rem;
  }
  
  /* Styling for Contact */
  
  .text-black {
    color: #000;
  }
  .contactImg {
    width: 30rem;
  }
  
  .heading {
    font-size: 3rem;
    font-weight: 800;
  }
  
  .form-control {
    border: none;
    background: #ebe8e8;
    margin-bottom: 1rem;
  }
  .form-control:active,
  .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000;
    background: #ebe8e8;
  }
  
  .form-control {
    height: 3.5rem;
  }
  
  /* Media Queries */
  @media screen and (max-width: 992px) {
    .card {
        width: 40rem;
    }
    .section {
        padding: 0;
    }
    .about-logos {
        margin-right: 2rem;
    }
  }
  @media screen and (max-width: 768px) {
    .card {
        width: 25rem;
    }
    .about-details h3 {
        font-size: 2.5rem;
    }
    .contact {
        flex-direction: column;
    }
    .contactImg {
        width: 18rem;
    }
    .about-container {
        flex-direction: column;
    }
    .img_profile {
        width: 13rem;
    }
  }
  @media screen and (max-width: 425px) {
    .card {
        height: 23rem;
        width: 20rem;
        margin: 0.5rem;
    }
    .img_profile {
        width: 10rem;
    }
    .about-details h3 {
        font-size: 2rem;
    }
    .about-details p {
        font-size: 1rem;
    }
    .contact {
        flex-direction: column;
    }
    .contactImg {
        width: 12rem;
    }
    .heading {
        font-size: 2rem;
        text-align: center;
    }
    .home_social-icon {
        font-size: 1.4rem;
    }
    .section {
        position: relative;
        height: 90vh;
    }
  }
  